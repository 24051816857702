.App {
  text-align: center;
  font-family: museo-sans;
  border: solid 1px #d8d8d8;
  background-color: #f7f7f7;
  min-width: 320px !important;
}

body {
  font-family: museo-sans;
  max-width: 1440px;
  margin: 0 auto;
  padding-right: 0 !important;
}

p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

button {
  cursor: pointer;

  &:disabled {
    cursor: default;
  }
}

.rmd-grid {
  padding-top: .25rem;
  padding-bottom: .25rem;
  grid-row-gap: .5rem;
}

.rmd-button--text {
  font-family: museo-sans;
  text-transform: unset;
}

.rmd-textarea {
  min-height: 0;
}

ul {
  list-style-type: none;
  margin: 10px 0;
  padding: 0;
  overflow: hidden;
  float: left;
}

li a {
  display: block;
  padding: 8px;
  background-color: #dddddd;
}

.dashboardSize {
  min-height: 100vh;
  max-width: 1300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto 30px !important;
}

.before {
 page-break-before: always;
}

.after {
  page-break-after: always;
}

.avoid {
  break-inside: avoid;
}

.welcomeEmailActDeactTopBand {
  height: 32px;
  padding-left: 30px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: solid 1px #ededed;
  background-color: #ededed;
  font-family: museo-sans, sans-serif;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #595959;
}

@media only screen and (max-width: 1199px) {
  .App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    min-width: 320px;
    overflow-x: hidden;
    border: none !important;

    .dashboardSize{
      min-height: auto;
      flex: 1;
    }
  }
}