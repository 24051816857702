.custom-switcher {
    &.rmd-switch-container {
        padding: 0;
    }

    & .rmd-switch {
        background-color: #979797;
        width: 30px;
        height: 18px;
        border-radius: 10px;
    }

    &--checked .rmd-switch {
        background-color: #2D3C59;
    }

    &--disabled {
        opacity: 0.5;
    }

    & .rmd-switch__ball {
        width: 28px;
        height: 28px;
        top: -20%;
        left: -10%;
        
        &::after {
            width: 12px;
            height: 12px;
            top: calc(50% - 7px);
            left: calc(50% - 7px);
            background-color: #fff !important;
        }
    }

    & .rmd-switch__input:checked {
        & + .rmd-switch__ball {
            transform: translateX(10px);

            &::after {
                background-color: #fff !important;
            }
        }
    } 
}