.rmd-button-welcome-email-invites {
    border: 1px solid #1e2133;
    border-radius: 3px;
}

#welcomeEmail-chkbox-0 {
    color: #fff;
}

.MuiBox-root {
    padding-left: 0 !important;
    margin: 0;
}

.MuiBox-root>.MuiFormControl-root {
    margin-right: 0 !important;
    width: 100%;
}

.cancelButtonInActive1 {
    width: 116px;
    height: 35px;
    border-radius: 0px;
    border: solid 2px #2d3b59;
    background-color: #fff;
    font-family: museo-sans;
    font-weight: 600;
    line-height: normal;
    letter-spacing: normal;
    line-height: 19.2px;
    color: #2d3b59;
    font-size: 15px;
    margin: 35px 5px 45px 5px;
    border: 2px solid #2e334e;
    Padding: 9px, 20px, 9px, 20px;
}

.disabledCancelButtonInActive1 {
    width: 116px;
    height: 35px;
    border-radius: 0px;
    border: solid 2px #979797;
    background-color: #fff;
    font-family: museo-sans;
    font-weight: 600;
    line-height: normal;
    letter-spacing: normal;
    line-height: 19.2px;
    color: #979797; /* Updated color */
    font-size: 15px;
    margin: 35px 5px 45px 5px;
    padding: 9px 20px; /* Corrected padding syntax */
    opacity: 0.75; /* Added opacity */
}

.emailInvites-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;
    margin: 0 auto;
}

.emailInvites-title {
    color: #2D3C59;
}

.emailInvites-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.emailInvites-actions button {
    font-family: museo-sans;
    font-weight: 600;
    width: 116px;
    height: 35px;
    font-size: 13px;
    line-height: 15.6px;
    padding: 9px 20px;
    border-radius: 3px;
    border-width: 1px;
    border-style: solid;
}

.emailInvites-activate {
    background-color: #2e334e;
    color: #ffffff;

    &:disabled {
        background-color: #979797;
    }
}

.emailInvites-deactivate {
    background-color: #ffffff;
    color: #2e334e;
    border-color: #4B4B4B;

    &:disabled {
        color: #979797;
        border-color: #979797;
    }
}

.welcome-email-actdeact-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.subtitle-text {
    font-family: Georgia;
    font-weight: bold;
    font-size: 21px;
}

#welcome-email-actdeact-dialog .MuiDataGrid-columnHeaders {
    background-color: #f7f7f7 !important;
}

#welcome-email-actdeact-dialog .MuiDataGrid-columnHeaderTitle {
    color: #2e334e;
    font-family: Museo Sans;
    font-size: 16px;
    font-weight: 700;
}


#welcome-email-actdeact-dialog .MuiDataGrid-columnHeadersInner {
    margin: 0 27px 0 27px;
}

#welcome-email-actdeact-dialog .MuiDataGrid-virtualScroller {
    margin: 0 27px 0 27px;
    border-bottom: 0px;
}

#welcome-email-actdeact-dialog .MuiDataGrid-root {
    border-bottom: none;
}

.welcome-email-deact-dialog .MuiDataGrid-row {
    font-family: 'museo-sans';
    color: #4e2e40;
    font-weight: 700;
}

.welcome-email-act-dialog .MuiDataGrid-row {
    font-family: 'museo-sans';
    color: 4b4b4b;
    font-weight: 500;
}