.error-dashboardSize {
    align-items: center;
}
.error-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    border-radius: 3px;
    border: solid 1px #d8d8d87a;
    background-color: white;
    padding: 70px 118px;    

    &.tablet-view {
        margin: 25px; 
        margin-top: 30px;
        padding: 30px 25px 75px 25px;  
    }
    &.mobile-view {
        margin: 20px; 
        padding: 30px 25px 75px 25px;  
    }
}

.error-icon {
    padding-right: 10px;    
    margin-bottom: 5px;
}

.error-title {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;

    /* Font */
    font-family: georgia;
    font-weight: 700;
    font-size: 21px;    
    line-height: 170%;
    color: #072C43;
    font-style: normal;

    &.tablet-view {        
        padding-bottom: 15px;
    }

    &.mobile-view {
        font-size: 21px; 
        flex-direction: column;
        width: auto;
        height: auto;
        line-height: 120%;

        .error-icon {        
            padding: 0px;
            margin-bottom: 15px;
            width: 40px;
        }
    }
}

.error-body {
    padding-top: 15px;
    padding-bottom: 30px;
    max-width: 704px;
    min-width: 400px;
    /* Font */
    font-family: museo-sans;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
    color: #757575;
    font-style: normal;

    &.tablet-view {
        padding: 15px 50px 25px 50px;
    }

    &.mobile-view {
        width: 100%;
        padding-top: 36px;
        padding-bottom: 20px;
        max-width: 100%;
        min-width: 100%;
        font-size: 16px;
    }

}

.error-footer {
    font-family: museo-sans;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #2e334e;    
    font-style: normal;
    
    &.mobile-view {
        font-size: 16px;
    }
}