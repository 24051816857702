:root {
    --font-georgia: Georgia;
    --font-museo: museo-sans;
    --color-dark: #2e334e;
    --color-text: #2d3b59;
    --color-secondary-text: #4b4b4b;
    --color-white: #ffffff;
    --border-radius-sm: 3px;
    --border-radius-md: 4px;
    --font-size-large: 21px;
    --font-size-normal: 14px;
    --font-size-small: 13px;
}

/* Container */
.welcomeEmailDialogContainer {
    padding: 34px;
    border-radius: var(--border-radius-md);
    background-color: #ffffff;
}

.successWelcomeEmailDialogContainer{
    padding: 43px 17px;
}

.welcomeEmailContentContainer {
    margin: 24px 0;
}

.successWelcomeEmailContentContainer{
    margin: 35px 0;
}

/* Title styles */
.successDialogTitle,
.welcomeEmailFailureDialogTitle {
    font-size: var(--font-size-large);
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: 0.07px;
    text-align: center;
    font-family: var(--font-georgia);
}

.successDialogTitle {
    color: var(--color-text);
}

.welcomeEmailFailureDialogTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-dark);
}

/* Dialog Text */
.cslFailureDialogText,
.cslSuccessDialogText {
    span {
        display: inline-block;
        margin: 0;
    }
    text-align: center;
}

.cslFailureDialogText {
    font-size: var(--font-size-normal);
    line-height: 19px;
    font-weight: 400;
    font-family: var(--font-museo);
    color: var(--color-secondary-text);    
}

.cslSuccessDialogText {
    font-family: var(--font-georgia);
    font-size: var(--font-size-large);
    font-weight: bold;
}

/* Buttons */
.successDialogButton {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    column-gap: 7px;
    padding: 0;
}

.buttonGeneral {
    width: 88px;
    height: 28px;
    border-radius: var(--border-radius-sm);
    border: solid 1.5px var(--color-dark);
    font-family: var(--font-museo);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    font-size: var(--font-size-small);
    font-weight: 600;
    padding: 0;
}

.cslSuccessCloseButton{
    width: 116px;
    line-height: 15.6px;
    background-color: var(--color-dark);
    color: var(--color-white);
}

.cslFailureCloseButton {
    background-color: var(--color-white);
    color: var(--color-dark);
}

.cslFailureTryAgainButton {
    background-color: var(--color-dark);
    color: var(--color-white);
}
