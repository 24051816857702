.errorHeaderText {
    font-size: 21px;
    font-weight: bold;
    line-height: 35px;
    text-align: center;
    letter-spacing: 0.07px;
    color: #2D3B59;
    font-family: Georgia;
}

.exportErrorMsg {
    font-size: 13px;
}

.exportErrorMsg.colorDarkGray {
    color: #4b4b4b;
}

.exchangeDashboardUrl .styledLink {
    text-decoration: none;
    color: #535C8D;
    /* font-family: 'Museo Sans'; */
    font-weight: 700 !important;
    position: relative;
    text-decoration: underline;
    margin: 0;
}

.exchangeDashboardUrl .styledLink::after {
    position: absolute;
    bottom: -2px;
    left: 0;
    background-color: #535C8D;
}

.exchangeDashboardUrl {
    text-decoration: underline;
    font-weight: 700 !important;
    color: #535c8d;
    cursor: pointer;
    /* font-family: 'Museo Sans'; */
    text-underline-offset: 2px;
}

.exportMenuItem:hover {
    background-color: #d8d8d8d2;
}