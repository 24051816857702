.manage-notifications-dialog {
    --suppressed-col-padding: 24px;
    width: 825px !important;

    .manage-notifications-header-container {
        min-height: 45px;
        height: 51px !important;
        padding: 0px 40px !important;
        border-top: solid 1px rgba(151, 151, 151, 0.25);
        border-bottom: solid 1px rgba(151, 151, 151, 0.25);
        background-color: #f7f7f7;
        align-items: center;

        .grid-suppressedBy-header {
            padding-left: var(--suppressed-col-padding);
        }
    }

    .manage-notifications-grid-content {
        position: relative;
    }

    .manage-notifications-grid-container {
        min-height: 45px;
        align-items: center;
        align-content: center;
        margin: 16px 40px !important;
        padding: 0px !important;
        font-size: 14px;
        line-height: 17px;

        .grid-email-cell {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            max-width: 220px;

            span {
                margin: 0px !important;
            }
        }

        .grid-suppressedBy-cell {
            padding-left: var(--suppressed-col-padding);

            .suppressedBy-text {
                display: flex;
                flex-direction: column;

                span {
                    text-overflow: ellipsis;
                    overflow: hidden;
                    margin: 0;
                }
            }
        }
    }

    .manage-notifications-loading-container {
        display: flex;
        width: 100%;
        min-height: 80px;
    }

    .manage-notifications-dialog-header {
        padding-top: 0px !important;
        padding-left: 40px !important;
        padding-right: 40px !important;
        padding-bottom: 0px !important;
    }

    .manage-notifications-dialog-body {
        padding: 0px !important;
    }

    .manage-notifications-grid-divider {
        margin-top: 10px !important;
    }

    .manage-notifications-dialog-footer {
        padding-bottom: 44px !important;
    }

    .manage-notifications-dialog-footer>div:first-child {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        gap: 20px;
    }

    .manage-notifications-header-text {
        font-size: 21px !important;
        font-weight: bold !important;
        color: #2E334E !important;
        font-family: 'Georgia';
        padding-top: 19px !important;
        margin-bottom: 0px !important;
    }

    .manage-notifications-sub-header-text {
        font-size: 14px !important;
        font-weight: normal !important;
        margin-top: 10px !important;
        color: #4B4B4B !important;
        margin-bottom: 27px !important;
    }

    .manage-notifications-cell-header {
        font-size: 14px !important;
        font-weight: 900 !important;
        color: #2E334E !important;
    }

    .manage-notifications-cell {
        color: #2E334E;
        font-weight: 400;
    }

    .gridcell-email-tooltip {
        margin: 0px !important;
    }

    .not-suppressed {
        font-style: italic !important;
        font-weight: 300 !important;
    }

    .manage-notifications-cancel-button-active {
        width: 108px;
        height: 35px;
        min-height: 35px;
        border-radius: 3.5px;
        border: solid 2.5px #2d3b59;
        background-color: #fff;
        font-family: museo-sans;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #2d3b59;

        &:disabled {
            border-color: #979797BF !important;
            color: #979797BF;
            cursor: not-allowed !important;
        }
    }

    .manage-notifications-hover-tooltip {
        margin: 10px !important;
        font-size: 14px !important;
        color: #fff;
    }
}

.manage-notifications-send-button {
    width: 108px;
    height: 35px;
    min-height: 35px;
    border-radius: 3.5px;
    background-color: #2E334E !important;
    font-family: museo-sans;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;

    &:disabled {
        background-color: #979797 !important;
        cursor: not-allowed !important;
    }
}

.manage-notifications-result-dialog {
    background-color: #f7f7f7 !important;
    border: 10px solid #F6F6F6;
    border-radius: 4px;
    width: 408px !important;
    height: auto !important;
    min-height: 250px !important;
    justify-content: center;
}

.manage-notifications-result-dialog-header {
    font-family: Georgia !important;
    font-weight: 700 !important;
    font-size: 21px !important;
    line-height: 35px;
    color: #2d3b59 !important;
    text-align: center;
    align-items: center;
    align-self: center;
    display: flex;
    padding-top: 0;
}

.manage-notifications-result-dialog-content {
    font-family: museo-sans !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    color: #757575 !important;
    line-height: 20px;
    text-align: center;
    align-items: center;
    align-self: center;
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    padding: 0;
}